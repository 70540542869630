import TotalReportHead from "@presentation/components/reports/TotalReportsHead";
import ReportRow from "@presentation/components/reports/TotalReportsRow";
import { ReportHeadCellData } from "@domain/Interfaces/HeadCellData.d";
import Order from "@domain/Interfaces/Order.d";
import { Box, TableContainer, Table, TableBody, TableProps } from "@mui/material";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import React, { useEffect, useState } from "react";
import { ReportHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Report } from "@domain/entities/Reports";
import { Dataset } from "@domain/entities/Roles";
import { User } from "@domain/entities/Users";
import {
  stableSort,
  getComparator,
  getPageNumber,
  calculateNumberOfEmptyRows,
  defaultTableSx,
} from "@presentation/components/table/utils";
import { getRowsPerPage, setRowsPerPage } from "@adapters/store/common/slice";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import EmptyRows from "@presentation/components/table/EmptyRow";

interface ITotalReportsTable extends TableProps {
  headCells: ReportHeadCell[];
  rows: Report[];
  datasets: Dataset[];
  users: User[];
  page: number;
  setPage: (page: number) => void;
}

const TotalReportsTable = ({
  headCells,
  rows,
  datasets,
  users,
  page,
  setPage,
}: ITotalReportsTable): React.JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ReportHeadCellData>("reportName");
  const rowsPerPage = useAppSelector(getRowsPerPage);
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState<number>(getPageNumber(rowsPerPage, rows.length));

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof ReportHeadCellData): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    setPage(0);
  };

  useEffect(() => {
    setPageNumber(getPageNumber(rowsPerPage, rows.length));
  }, [rowsPerPage, rows]);

  const emptyRows = calculateNumberOfEmptyRows(page, rowsPerPage, rows.length);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={defaultTableSx} aria-labelledby="tableTitle">
          <TotalReportHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <ReportRow
                    key={`report-list-${row.id}`}
                    apiReport={row}
                    datasets={datasets}
                    users={users}
                    labelId={labelId}
                    highlight={row.datasetId === 0}
                  />
                );
              })}
            <EmptyRows emptyNumber={emptyRows} />
          </TableBody>
        </Table>
      </TableContainer>
      <TotalTablePagination
        page={page}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        nbItems={rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default TotalReportsTable;
