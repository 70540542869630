import { useEffect, useState } from "react";
import TotalReportsTable from "@presentation/components/reports/TotalReportsTable";
import { ReportHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getFavorite, listReports } from "@adapters/store/reports/thunk";
import { getFavoriteReports, getViewableReports } from "@adapters/store/reports/slice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { listDatasets } from "@adapters/store/datasets/thunk";
import { listUsers } from "@adapters/store/users/thunk";
import { getDatasets } from "@adapters/store/datasets/slice";
import { getUsers } from "@adapters/store/users/slice";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";
import { Report } from "@domain/entities/Reports";
import TextFieldAutocomplete from "@presentation/components/inputs/TextFieldAutocomplete";
import { useNavigate } from "react-router-dom";
import TotalCheckbox from "@presentation/components/TotalCheckbox";
import { InputLabel } from "@presentation/components/inputs/atoms/InputLabel";

const Reports = (): React.JSX.Element => {
  const { t } = useTranslation();
  const headCells: ReportHeadCell[] = [
    {
      id: "reportName",
      numeric: false,
      disablePadding: true,
      label: t("see_reports.headers.report"),
      enableSorting: true,
    },
    {
      id: "datasetId",
      numeric: false,
      disablePadding: false,
      label: t("see_reports.headers.dataset"),
      enableSorting: true,
    },
    {
      id: "reportType",
      numeric: false,
      disablePadding: false,
      label: t("see_reports.headers.type"),
      enableSorting: true,
    },
  ];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rows = useAppSelector(getViewableReports);
  const datasets = useAppSelector(getDatasets);
  const users = useAppSelector(getUsers);
  const favorite = useAppSelector(getFavoriteReports);
  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);
  const [onlyFavorite, setOnlyFavorite] = useState<boolean>(false);
  const [reportList, setReportList] = useState<Report[]>(rows);
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState<string>("");

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(listReports());
    dispatch(getFavorite());
    dispatch(listDatasets());
    dispatch(listUsers());
  }, [dispatch]);

  useEffect(() => {
    let newRows = rows;
    if (search.length >= 1) {
      newRows = newRows.filter((report: Report) => report.reportName.toLowerCase().includes(search.toLowerCase()));
    }
    if (onlyFavorite) {
      newRows = newRows.filter((report: Report) => favorite.includes(report.id));
    }
    setReportList(newRows);
  }, [rows, search, favorite, onlyFavorite]);

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", gap: "4px", width: "100%" }}>
        <Box sx={{ alignItems: "center", my: "10px", display: "flex", height: "fit-content" }}>
          <Typography sx={{ ml: "22px", color: "#374649" }} variant="h2">
            {t("see_reports.title", { count: rows.length })}
          </Typography>
          <TotalTooltip title={t("see_reports.description")} setOpenState={setInfoTooltipState}>
            <Box>
              <TooltipIconTrigger tooltipState={infoTooltipState}>
                <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
              </TooltipIconTrigger>
            </Box>
          </TotalTooltip>
        </Box>
        <Box sx={{ marginLeft: "auto", mr: "24px", display: "flex", flexDirection: "column", mt: "10px" }}>
          <TextFieldAutocomplete
            label={t("search_bar.search")}
            textPlace="left"
            onChange={handleSearch}
            options={rows.map((report: Report) => ({
              title: report.reportName,
              onClick: () => {
                if (report.roles.length > 0) {
                  navigate("/reports/" + report.id);
                }
              },
            }))}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              ml: "auto",
            }}
            onClick={() => {
              setOnlyFavorite(!onlyFavorite);
              setPage(0);
            }}
          >
            <Box>
              <TotalCheckbox checked={onlyFavorite} tabIndex={-1} disableRipple />
            </Box>
            <InputLabel label={t("see_reports.only_favorite")} sx={{ my: "auto" }} />
          </Box>
        </Box>
      </Box>
      <TotalReportsTable
        headCells={headCells}
        rows={reportList}
        datasets={datasets}
        users={users}
        page={page}
        setPage={setPage}
      />
    </Grid>
  );
};

export default Reports;
