import { RootState } from "@core/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface CommonState {
  rowsPerPage: number;
}

// Define the initial state using that type
const initialState: CommonState = {
  rowsPerPage: 10,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setRowsPerPage: (state: CommonState, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
    },
  },
});

// Define actions to be used in UI here
export const { setRowsPerPage } = commonSlice.actions;

// Define selectors to be used in UI here
export const getRowsPerPage = (state: RootState): number => state.common.rowsPerPage;
