import { useEffect, useState } from "react";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { listReports } from "@adapters/store/reports/thunk";
import { getReports } from "@adapters/store/reports/slice";
import { getDatasets } from "@adapters/store/datasets/slice";
import { listDatasets } from "@adapters/store/datasets/thunk";
import { listRoles } from "@adapters/store/roles/thunk";
import { Dataset } from "@domain/entities/Datasets";
import DatasetsListCollapsibleCard from "@presentation/components/DatasetsListCollapsibleCard";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import { getRoles } from "@adapters/store/roles/slice";
import { Role } from "@domain/entities/Roles";
import { Link } from "react-router-dom";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { Report } from "@domain/entities/Reports";
import TotalTooltip from "@presentation/components/TotalTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";

const Datasets = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const datasets = useAppSelector(getDatasets);
  const reports = useAppSelector(getReports);
  const roles = useAppSelector(getRoles);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState<number>(Number(Math.ceil(datasets.length / rowsPerPage)));

  useEffect(() => {
    dispatch(listReports());
    dispatch(listDatasets());
    dispatch(listRoles());
  }, [dispatch]);

  useEffect(() => {
    setPageNumber(Number(Math.ceil(datasets.length / rowsPerPage)));
  }, [rowsPerPage, datasets]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start" sx={{ width: "100%", px: "22px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography sx={{ my: "17px", color: "#374649" }} variant="h2">
          {t("datasets_view.title", { count: datasets.length })}
        </Typography>
        <TotalTooltip title={t("datasets_view.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
      </Box>
      <Box sx={{ width: "100%", mb: "16px" }}>
        {datasets.map((dataset: Dataset, idxa: number) => {
          const nbReportsLinked = reports.filter((report: Report) => report.datasetId === dataset.id).length;
          return (
            <DatasetsListCollapsibleCard
              key={"card-" + idxa}
              title={dataset.datasetName}
              dataset={dataset}
              sx={{ mt: "16px" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="summaryTableHead">{t("datasets_view.roles")}</Typography>
                  {roles.filter((x: Role) => x.dataset.id === dataset.id).length <= 0 ? (
                    <Typography variant="menu1" sx={{ ml: "9px" }}>
                      {t("common.none")}
                    </Typography>
                  ) : (
                    <Typography variant="menu1" sx={{ ml: "9px", maxWidth: "50%" }}>
                      {roles
                        .filter((x: Role) => {
                          return x.dataset.id === dataset.id;
                        })
                        .map((role: Role, idx: number) => {
                          return role.roleName + (idx < roles.length - 1 ? ", " : "");
                        })}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: "16px" }}>
                  <Typography variant="summaryTableHead">
                    {t("datasets_view.report_linked_to", { count: nbReportsLinked })}
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", mt: "16px", gap: "28px" }}>
                    {reports
                      .filter((report: Report) => report.datasetId === dataset.id)
                      .map((report: Report, idxb: number) => {
                        return (
                          <Box key={"report-" + idxb}>
                            <Box sx={{ display: "flex" }}>
                              <Typography variant="menu1">
                                <Link to={"/reports/" + report.id} target="_blank" rel="noreferrer">
                                  <Typography
                                    style={{
                                      color: theme.palette.primary.main,
                                      display: "inline-flex",
                                      alignContent: "center",
                                    }}
                                  >
                                    {report.reportName}
                                    <CallMadeOutlinedIcon
                                      sx={{
                                        color: theme.palette.secondary.main,
                                        height: "14px",
                                        translate: "-2px 5px",
                                        alignSelf: "stretch",
                                      }}
                                    />
                                  </Typography>
                                </Link>
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </DatasetsListCollapsibleCard>
          );
        })}
      </Box>
      <TotalTablePagination
        page={page}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        nbItems={datasets.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default Datasets;
