import TotalTableHead from "@presentation/components/scopes/TotalScopesHead";
import TotalTableRow from "@presentation/components/scopes/TotalScopesRow";
import { ScopeHeadCellData } from "@domain/Interfaces/HeadCellData.d";
import Order from "@domain/Interfaces/Order.d";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import React, { useEffect, useState } from "react";
import { ScopeHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Scope } from "@domain/entities/Scopes";
import {
  stableSort,
  getComparator,
  calculateNumberOfEmptyRows,
  getPageNumber,
  defaultTableSx,
} from "@presentation/components/table/utils";
import EmptyRows from "@presentation/components/table/EmptyRow";
import { getRowsPerPage, setRowsPerPage } from "@adapters/store/common/slice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@core/store/hook";

type ScopeTableProps = {
  headCells: ScopeHeadCell[];
  rows: Scope[];
};

const TotalScopesTable = ({ headCells, rows }: ScopeTableProps): React.JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ScopeHeadCellData>("scopeName");
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(getRowsPerPage);
  const [pageNumber, setPageNumber] = useState<number>(getPageNumber(rowsPerPage, rows.length));
  const dispatch = useAppDispatch();

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof ScopeHeadCellData): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    setPage(0);
  };

  useEffect(() => {
    setPageNumber(getPageNumber(rowsPerPage, rows.length));
  }, [rowsPerPage, rows]);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={defaultTableSx} aria-labelledby="tableTitle">
          <TotalTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TotalTableRow
                    key={`scopes-list-${row.id}`}
                    rows={rows}
                    row={row}
                    highlight={row.datasetLength === 0}
                  />
                );
              })}
            <EmptyRows emptyNumber={calculateNumberOfEmptyRows(page, rowsPerPage, rows.length)} />
          </TableBody>
        </Table>
      </TableContainer>
      <TotalTablePagination
        page={page}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        nbItems={rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default TotalScopesTable;
