import TotalTableHead from "@presentation/components/roles/TotalTableHead";
import TotalTableRow from "@presentation/components/roles/TotalTableRow";
import { RoleHeadCellData } from "@domain/Interfaces/HeadCellData.d";
import Order from "@domain/Interfaces/Order.d";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import React, { useEffect, useState } from "react";
import { RoleHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Role } from "@domain/entities/Roles";
import { defaultTableSx, getPageNumber } from "@presentation/components/table/utils";
import EmptyRows from "@presentation/components/table/EmptyRow";
import { useSelector } from "react-redux";
import { getRowsPerPage, setRowsPerPage } from "@adapters/store/common/slice";
import { useAppDispatch } from "@core/store/hook";

function descendingComparator<T extends Role>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy] || (b[orderBy] === a[orderBy] && b["datasetName"] < a["datasetName"])) {
    return -1;
  }
  if (b[orderBy] > a[orderBy] || (b[orderBy] === a[orderBy] && b["datasetName"] > a["datasetName"])) {
    return 1;
  }
  return 0;
}

function getComparator<T extends Role, Key extends keyof T>(order: Order, orderBy: Key): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T extends Role>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type RoleTableProps = {
  headCells: RoleHeadCell[];
  rows: Role[];
};

const TotalRolesTable = ({ headCells, rows }: RoleTableProps): React.JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof RoleHeadCellData>("roleName");
  const [page, setPage] = React.useState(0);
  const dispatch = useAppDispatch();
  const rowsPerPage = useSelector(getRowsPerPage);
  const [pageNumber, setPageNumber] = useState<number>(getPageNumber(rowsPerPage, rows.length));

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof RoleHeadCellData): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    setPage(0);
  };

  useEffect(() => {
    setPageNumber(getPageNumber(rowsPerPage, rows.length));
  }, [rowsPerPage, rows]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={defaultTableSx} aria-labelledby="tableTitle">
          <TotalTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return <TotalTableRow key={`role-list-${row.id}`} row={row} highlight={row.scopes.length === 0} />;
              })}
            <EmptyRows emptyNumber={emptyRows} />
          </TableBody>
        </Table>
      </TableContainer>
      <TotalTablePagination
        page={page}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        nbItems={rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default TotalRolesTable;
