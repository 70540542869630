import { Input, InputProps, useTheme } from "@mui/material";

type InputTextFieldProps = Omit<InputProps, "color"> & {
  color?: string;
  onChangeCustom: (e: string) => void;
};

/**
 * InputTextField Component
 *
 * A text field component that wraps a Material-UI Input component with additional styling and functionality.
 * It supports custom color, error state, and disabled state.
 *
 * @param {string} [color="white"] - The background color of the input field.
 * @param {function} onChange - The callback function that is called when the input value changes. It receives the new value as a string.
 * @param {boolean} [error=false] - If true, the input field is styled to indicate an error state.
 * @param {boolean} [disabled=false] - If true, the input field is disabled and styled accordingly.
 * @param {string} placeholder - The placeholder text for the input field.
 * @param {InputProps} props - Additional Material-UI [InputProps](https://mui.com/material-ui/api/input/) not related to color or onChange handler.
 * @returns {React.JSX.Element} A React JSX element representing the custom styled input field.
 */
export const InputTextField = ({
  color = "white",
  onChangeCustom,
  error = false,
  disabled = false,
  sx,
  onChange,
  ...props
}: InputTextFieldProps): React.JSX.Element => {
  const theme = useTheme();
  return (
    <Input
      sx={{
        cursor: "default",
        backgroundColor: color,
        border: !error ? theme.custom.select.border.size : 2,
        borderRadius: theme.custom.select.border.radius,
        borderStyle: "solid",
        borderColor: !error ? theme.custom.select.border.color : theme.palette.secondary.main,
        width: "100%",
        height: "32px",
        px: 1,
        ...(error && { color: theme.palette.secondary.main }),
        ...(disabled && { backgroundColor: theme.custom.disabled, cursor: "not-allowed" }),
        ...sx,
      }}
      disableUnderline
      {...props}
      onChange={(event) => {
        if (onChange) onChange(event);
        if (onChangeCustom) onChangeCustom(event.target.value);
      }}
    />
  );
};
