import Home from "@presentation/views/Home.view";
import Roles from "@presentation/views/Roles.view";
import CreateRole from "@presentation/views/CreateRole.view";
import CreateScope from "@presentation/views/CreateScope.view";
import Scopes from "@presentation/views/Scopes.view";
import ViewRole from "@presentation/views/ViewRole.view";
import ViewScope from "@presentation/views/ViewScope.view";
import App from "@core/App";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Groups from "@presentation/views/Groups.view";
import CreateGroup from "@presentation/views/CreateGroup.view";
import ViewReport from "@presentation/views/ViewReport.view";
import Reports from "@presentation/views/Reports.view";
import Users from "@presentation/views/Users.view";
import CreateUser from "@presentation/views/CreateUser.view";
import ViewUser from "@presentation/views/ViewUser.view";
import Login from "@presentation/views/Login.view";
import ViewGroup from "@presentation/views/ViewGroup.view";
import Datasets from "@presentation/views/Datasets.view";
import Authorize from "@presentation/views/Authorize.view";
import Rejected from "@presentation/views/Rejected.view";
import Authenticate from "@presentation/views/Authenticate.view";
import AuthenticationCallback from "@presentation/views/AuthenticationCallback.view";
import { useAppSelector } from "@core/store/hook";
import { getAuthUser, getIsAuthenticated } from "@adapters/store/users/slice";
import NotFound from "@presentation/views/NotFound.view";
import { isUriAuthorized } from "@core/right/Right";
import Maintenances from "@presentation/views/maintenance/MaintenancesList.view";
import React from "react";
import CreateMaintenance from "@presentation/views/maintenance/MaintenanceCreate.view";
import ViewMaintenance from "@presentation/views/maintenance/ViewMaintenance.view";
import InMaintenance from "@presentation/views/InMaintenance.view";

export const RequireAuth = ({ children }: { children: React.JSX.Element | null }): React.JSX.Element | null => {
  const isAuth = useAppSelector(getIsAuthenticated);
  const authUser = useAppSelector(getAuthUser);
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to="/authenticate" state={{ from: location }} />;
  }
  if (location.pathname === "/") return children;
  if (!isUriAuthorized(location.pathname, authUser?.userRight)) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

export const DoNotShowIfAuth = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const isAuth = useAppSelector(getIsAuthenticated);
  const location = useLocation();

  if (isAuth) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

const AppRouter = (): React.JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route path="login">
          <Route index element={<Login />} />
        </Route>
        <Route path="authenticate">
          <Route
            index
            element={
              <DoNotShowIfAuth>
                <Authenticate />
              </DoNotShowIfAuth>
            }
          />
        </Route>
        <Route path="auth/callback">
          <Route
            index
            element={
              <DoNotShowIfAuth>
                <AuthenticationCallback />
              </DoNotShowIfAuth>
            }
          />
        </Route>
        <Route path="authorize">
          <Route index element={<Authorize />} />
        </Route>
        <Route path="rejected">
          <Route index element={<Rejected />} />
        </Route>
        <Route path="datasets">
          <Route
            index
            element={
              <RequireAuth>
                <Datasets />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="reports">
          <Route
            index
            element={
              <RequireAuth>
                <Reports />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="users">
          <Route
            index
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="users/:id">
          <Route
            index
            element={
              <RequireAuth>
                <ViewUser />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="users/create">
          <Route
            index
            element={
              <RequireAuth>
                <CreateUser />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="groups">
          <Route
            index
            element={
              <RequireAuth>
                <Groups />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="groups/:id">
          <Route
            index
            element={
              <RequireAuth>
                <ViewGroup />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="roles">
          <Route
            index
            element={
              <RequireAuth>
                <Roles />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="roles/:id">
          <Route
            index
            element={
              <RequireAuth>
                <ViewRole />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="scopes">
          <Route
            index
            element={
              <RequireAuth>
                <Scopes />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="scopes/:id">
          <Route
            index
            element={
              <RequireAuth>
                <ViewScope />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="roles/create">
          <Route
            index
            element={
              <RequireAuth>
                <CreateRole />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="scopes/create">
          <Route
            index
            element={
              <RequireAuth>
                <CreateScope />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="groups/create">
          <Route
            index
            element={
              <RequireAuth>
                <CreateGroup />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="reports/:id">
          <Route
            index
            element={
              <RequireAuth>
                <ViewReport />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="maintenances">
          <Route
            index
            element={
              <RequireAuth>
                <Maintenances />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="maintenances/create">
          <Route
            index
            element={
              <RequireAuth>
                <CreateMaintenance />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="maintenances/:id">
          <Route
            index
            element={
              <RequireAuth>
                <ViewMaintenance />
              </RequireAuth>
            }
          />
        </Route>
      </Route>
      <Route path="maintenance" element={<InMaintenance />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
