import { useEffect, useState } from "react";
import TotalScopesTable from "@presentation/components/scopes/TotalScopesTable";
import { ScopeHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getScopes, getScopesByDataset } from "@adapters/store/scopes/slice";
import { listScopes } from "@adapters/store/scopes/thunk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import TotalIconButtonSecondary from "@presentation/components/TotalIconButtonSecondary";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";

const Scopes = (): JSX.Element => {
  const { t } = useTranslation();
  const headCells: ScopeHeadCell[] = [
    {
      id: "scopeName",
      numeric: false,
      disablePadding: true,
      label: t("manage_scopes.headers.scope_name"),
      enableSorting: true,
    },
    {
      id: "datasetLength",
      numeric: false,
      disablePadding: false,
      label: t("manage_scopes.headers.dataset"),
      enableSorting: true,
    },
    {
      id: "totalRoles",
      numeric: true,
      disablePadding: false,
      label: t("manage_scopes.headers.roles"),
      enableSorting: false,
    },
  ];

  const dispatch = useAppDispatch();
  const rows = useAppSelector(getScopes);

  const scopesList = useAppSelector(getScopesByDataset);

  useEffect(() => {
    dispatch(listScopes());
  }, [dispatch]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("manage_scopes.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("manage_scopes.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
      </Box>
      {scopesList.map((datasetScopes) => (
        <CollapsibleCard
          key={`collapsible-scope-${datasetScopes.datasetIds.join("-")}`}
          title={datasetScopes.name}
          sx={{ mt: "8px", ml: "8px" }}
          cardSx={{ backgroundColor: "transparent", boxShadow: "none" }}
          titleCardSx={{ cursor: "pointer" }}
          contentSx={{ padding: "0px" }}
          dividerAlwaysVisible
          dividerSx={{ mx: 0 }}
          icon={
            <TotalIconButtonSecondary>
              <ArrowDownIcon sx={{ height: "15px", width: "15px" }} />
            </TotalIconButtonSecondary>
          }
        >
          <TotalScopesTable headCells={headCells} rows={datasetScopes.scopes}></TotalScopesTable>
        </CollapsibleCard>
      ))}
    </Grid>
  );
};

export default Scopes;
