import { configureStore } from "@reduxjs/toolkit";
import { multiClientMiddleware } from "redux-axios-middleware";
import { createLogger } from "redux-logger";
import { rolesSlice } from "@adapters/store/roles/slice";
import { httpClient } from "@adapters/api/HttpClient";
import { scopesSlice } from "@adapters/store/scopes/slice";
import { datasetsSlice } from "@adapters/store/datasets/slice";
import { groupsSlice } from "@adapters/store/groups/slice";
import { reportsSlice } from "@adapters/store/reports/slice";
import { usersSlice } from "@adapters/store/users/slice";
import { maintenancesSlice } from "@adapters/store/maintenances/slice";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { scheduledSlice } from "@adapters/store/scheduled/slice";
import { inProduction } from "@core/utils/Tools";
import { commonSlice } from "@adapters/store/common/slice";

const api = {
  default: {
    client: httpClient.getClient(),
  },
};

// Just a way to avoid the logger in production, this is not a feature disabled in production
const middleware = inProduction()
  ? (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(multiClientMiddleware(api))
  : (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(multiClientMiddleware(api), createLogger());

const store = configureStore({
  reducer: {
    roles: rolesSlice.reducer,
    scopes: scopesSlice.reducer,
    datasets: datasetsSlice.reducer,
    groups: groupsSlice.reducer,
    reports: reportsSlice.reducer,
    users: usersSlice.reducer,
    scheduled: scheduledSlice.reducer,
    maintenances: maintenancesSlice.reducer,
    common: commonSlice.reducer,
  },
  middleware: middleware,
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export interface ErrorState {
  statusCode: number;
  message: string;
}

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export { store };
