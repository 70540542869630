import Order from "@domain/Interfaces/Order.d";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import React, { useEffect, useState } from "react";
import {
  calculateNumberOfEmptyRows,
  defaultTableSx,
  getComparator,
  getPageNumber,
  stableSort,
} from "@presentation/components/table/utils";
import EmptyRows from "@presentation/components/table/EmptyRow";
import { useSelector } from "react-redux";
import { getRowsPerPage, setRowsPerPage } from "@adapters/store/common/slice";
import { useAppDispatch } from "@core/store/hook";
import { Maintenance } from "@domain/entities/Maintenance";
import MaintenanceHead from "@presentation/components/maintenance/MaintenanceHead";
import MaintenanceRow from "@presentation/components/maintenance/MaintenanceRow";
import { MaintenanceHeadCell } from "@domain/Interfaces/HeadCell.d";
import { MaintenanceHeadCellData } from "@domain/Interfaces/HeadCellData";

type MaintenanceTableProps = {
  headCells: MaintenanceHeadCell[];
  rows: Maintenance[];
};

const MaintenanceTable = ({ headCells, rows }: MaintenanceTableProps): React.JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof MaintenanceHeadCellData>("startDate");
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(getRowsPerPage);
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState<number>(getPageNumber(rowsPerPage, rows.length));

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof MaintenanceHeadCellData): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    setPage(0);
  };

  useEffect(() => {
    setPageNumber(getPageNumber(rowsPerPage, rows.length));
  }, [rowsPerPage, rows]);

  const emptyRows = calculateNumberOfEmptyRows(page, rowsPerPage, rows.length);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={defaultTableSx} aria-labelledby="tableTitle">
          <MaintenanceHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {stableSort<Maintenance>(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return <MaintenanceRow key={`maintenance-list-${row.id}`} row={row} />;
              })}
            <EmptyRows emptyNumber={emptyRows} />
          </TableBody>
        </Table>
      </TableContainer>
      <TotalTablePagination
        page={page}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        nbItems={rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default MaintenanceTable;
