import { httpClient } from "@adapters/api/HttpClient";
import { MaintenanceCreateRequest, MaintenanceResponse, MaintenanceUpdateRequest } from "@domain/entities/Maintenance";
import { AxiosResponse } from "axios";

export const maintenancesServices = {
  list: async (): Promise<AxiosResponse<MaintenanceResponse[]>> => {
    return httpClient.get("/v1/maintenance");
  },
  delete: async (id: number): Promise<AxiosResponse<boolean>> => {
    return httpClient.delete(`/v1/maintenance/${id}`);
  },
  create: async (maintenance: MaintenanceCreateRequest): Promise<AxiosResponse<MaintenanceResponse>> => {
    return httpClient.put<MaintenanceCreateRequest, MaintenanceResponse>("/v1/maintenance", maintenance);
  },
  update: async (maintenance: MaintenanceUpdateRequest): Promise<AxiosResponse<MaintenanceResponse>> => {
    return httpClient.put<MaintenanceUpdateRequest, MaintenanceResponse>(`/v1/maintenance`, maintenance);
  },
  actual: async (): Promise<AxiosResponse<MaintenanceResponse>> => {
    return httpClient.get("/v1/maintenance/actual");
  },
};
