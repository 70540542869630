import { reportsServices } from "@adapters/api/services/ReportsServices";
import { ReportDownloadResponse } from "@adapters/store/reports/slice";
import { Report } from "@domain/entities/Reports";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const listReports = createAsyncThunk("reports/list", async (_, { rejectWithValue }) => {
  try {
    const response = await reportsServices.listReports();
    return response.data as Report[];
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getFavorite = createAsyncThunk("reports/favorite", async (_, { rejectWithValue }) => {
  try {
    const response = await reportsServices.getFavorites();
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const addFavorite = createAsyncThunk("reports/addFavorite", async (reportId: number, { rejectWithValue }) => {
  try {
    await reportsServices.addFavorite(reportId);
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const removeFavorite = createAsyncThunk(
  "reports/removeFavorite",
  async (reportId: number, { rejectWithValue }) => {
    try {
      await reportsServices.removeFavorite(reportId);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReport = createAsyncThunk("reports/init", async (reportId: number, { rejectWithValue }) => {
  try {
    const response = await reportsServices.getReport(reportId);
    return response.data as Report;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getReportPolling = createAsyncThunk("reports/get", async (pollingId: string, { rejectWithValue }) => {
  try {
    const { data } = await reportsServices.getReportPolling(pollingId);
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateUsersToReport = createAsyncThunk(
  "reports/updateUsers",
  async (args: { reportId: number; users: number[] }, { rejectWithValue }) => {
    try {
      const response = await reportsServices.updateUsersToReport(args.reportId, args.users);
      return response.data as Report;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeUsersToReport = createAsyncThunk(
  "reports/removeUsers",
  async (args: { reportId: number; users: number[] }, { rejectWithValue }) => {
    try {
      const response = await reportsServices.removeUsersToReport(args.reportId, args.users);
      return response.data as Report;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const initializeDownload = createAsyncThunk(
  "reports/initDownload",
  async (reportId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await reportsServices.initializeDownload(reportId);
      const reportDownload: ReportDownloadResponse = {
        reportId,
        ...response.data,
      };
      dispatch(loopDownloadReport(reportDownload));
      return reportDownload;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadReport = createAsyncThunk(
  "reports/download",
  async (args: { reportId: number; downloadId: string }, { rejectWithValue }) => {
    try {
      const response = await reportsServices.downloadReport(args.reportId, args.downloadId);
      return { data: response.data, status: response.status };
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const loopDownloadReport = createAsyncThunk(
  "reports/loopDownload",
  async (args: { reportId: number; downloadId: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await reportsServices.downloadReport(args.reportId, args.downloadId);
      if (response.status === 204) {
        setTimeout(() => {
          dispatch(loopDownloadReport(args));
        }, 300);
      }
      return { data: response.data, status: response.status, headers: response.headers };
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
