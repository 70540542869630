import { httpClient } from "@adapters/api/HttpClient";
import { ReportDownloadRaw } from "@adapters/store/reports/slice";
import { EmbedToken } from "@domain/entities/Reports";

export const reportsServices = {
  listReports: async () => {
    return httpClient.get(`/v1/reports`);
  },
  getReport: async (report: number) => {
    return httpClient.get(`/v1/reports/init/${report}`);
  },
  getReportPolling: async (pollingId: string) => {
    return httpClient.get<EmbedToken | string>(`/v1/reports/get/${pollingId}`);
  },
  getFavorites: async () => {
    return httpClient.get<number[]>(`v1/reports/favorite`);
  },
  addFavorite: async (reportId: number) => {
    return httpClient.post(`/v1/reports/favorite/add/${reportId}`, {});
  },
  removeFavorite: async (reportId: number) => {
    return httpClient.post(`/v1/reports/favorite/remove/${reportId}`, {});
  },
  updateUsersToReport: async (report: number, users: number[]) => {
    return httpClient.post(`/v1/reports/${report}/updateUsers`, { users });
  },
  removeUsersToReport: async (report: number, users: number[]) => {
    return httpClient.post(`/v1/reports/${report}/removeUsers`, { users });
  },
  initializeDownload: async (report: number) => {
    return httpClient.get<ReportDownloadRaw>(`/v1/reports/${report}/download/init`);
  },
  downloadReport: async (report: number, downloadId: string) => {
    return httpClient.get(`/v1/reports/${report}/download/get`, {
      params: { downloadId: downloadId },
    });
  },
};
